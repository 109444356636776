import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { ContactsDTO, ContactPostDTO } from '../http/dto/ContactDTO';
import { DataClients, ContactsPost } from '../models/Contact'
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const contactRepository = {
   getContact: async (perPage, page): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const contact = await http.get<any>(`${API_URL_BASE}/v1/contact?per_page=${perPage}&page=${page}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = contact;

      return {
         data: {
            clients: data.clients.data,
            entities: data.entities.data,
            clients_all: data.clients.data.concat(data.entities.data),
            total_clients: data.clients.total,
            total_entity: data.entities.total
         },
         error,
         message
      }
   },
   getContactAll: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const contact = await http.get<any>(`${API_URL_BASE}/v1/contact?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = contact;

      return {
         data: {
            clients: data.clients,
            entities: data.entities,
            clients_all: data.clients.concat(data.entities)
         },
         error,
         message
      }
   },
   getContactByArriendo: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const contact = await http.get<any>(`${API_URL_BASE}/v1/contact/byArriendo?rut_empresa=${rut_empresa}`)
      const { data, error, message } = contact;

      return {
         data,
         error,
         message
      }
   },
   getSearchContacts: async (search: string): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const users = await http.get<any>(`${API_URL_BASE}/v1/contact?search=${search}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = users;
      return {
         data,
         error,
         message
      }
   },
   getDataInitial: async (): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const response = await http.get<any>(`${API_URL_BASE}/v1/contact/data?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`);
      const { data, error, message } = response;
      return {
         data,
         error,
         message
      }
   },
   createContact: async (dataContact): Promise<ContactsPost> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<ContactPostDTO>(`${API_URL_BASE}/v1/contact`, {
         rut_cliente: dataContact.rut_cliente,
         nombre_rz: dataContact.nombre_rz,
         nombre_fantasia: dataContact.nombre_fantasia,
         giro: dataContact.giro,
         fax: dataContact.fax,
         prospecto: dataContact.prospecto,
         observacion: dataContact.observacion,
         extranjero: dataContact.extranjero,
         web: dataContact.web,
         direccion: dataContact.direccion,
         idtipopersona: dataContact.idtipopersona,
         idtiporubro: dataContact.idtiporubro,
         id_empresa: id_empresa,
         //  idusuario_vendedor: dataContact.id_tipousuario_vendedor,
         idcomuna: dataContact.idcomuna,
         cargo: dataContact.cto_cargo,
         email: dataContact.cto_email,
         nombre: dataContact.cto_nombre,
         telefono: dataContact.cto_telefono,
         celular: dataContact.cto_celular,
         cobranza: dataContact.cto_cobranza,
         is_prospecto: '1'
         // idempresacliente: idempresacliente.user.idempresacliente
      })
      return {
         // error: resp.error,
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },
   verifyRut: async (rut_cliente: string): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/contact/verify-rut`, {
         rut_cliente: rut_cliente
      })

      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      }
   },
   // updateContact: async (id: number, dataContact): Promise<any> => {
   //    const resp = await http.patch<any>(`${API_URL_BASE}/v1/contact/${id}`, {
   //       rut_cliente: dataContact.rut_cliente,
   //       nombre_rz: dataContact.nombre_rz,
   //       nombre_fantasia: dataContact.nombre_fantasia,
   //       giro: dataContact.giro,
   //       fax: dataContact.fax,
   //       prospecto: dataContact.prospecto,
   //       observacion: dataContact.observacion,
   //       extranjero: dataContact.extranjero,
   //       web: dataContact.web,
   //       direccion: dataContact.direccion,
   //       idtipopersona: dataContact.id_tipopersona,
   //       idtiporubro: dataContact.id_tiporubro,
   //       idusuario_vendedor: dataContact.id_tipousuario_vendedor,
   //       idcomuna: dataContact.id_comuna,
   //       cargo: dataContact.cto_cargo,
   //       email: dataContact.cto_email,
   //       nombre: dataContact.cto_nombre,
   //       telefono: dataContact.cto_telefono,
   //       celular: dataContact.cto_celular,
   //       cobranza: dataContact.cto_cobranza,
   //       is_prospecto:'1'
   //    });
   updateContact: async (id: number, dataContact): Promise<any> => {
      console.log(dataContact);
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/contact/${id}`, {
         rut_cliente: dataContact.rut_cliente?dataContact.rut_cliente:'',
         nombre_rz: dataContact.nombre_rz?dataContact.nombre_rz:'',
         nombre_fantasia: dataContact.nombre_fantasia? dataContact.nombre_fantasia: '',
         giro: dataContact.giro?dataContact.giro:'',
         fax: dataContact.fax?dataContact.fax:'',
         prospecto: dataContact.prospecto?dataContact.prospecto:'',
         observacion: dataContact.observacion?dataContact.observacion:'',
         extranjero: dataContact.extranjero?dataContact.extranjero:'',
         web: dataContact.web?dataContact.web:'',
         direccion: dataContact.direccion?dataContact.direccion:'',
         idtipopersona: dataContact.id_tipopersona? dataContact.id_tipopersona:null,
         idtiporubro: dataContact.id_tiporubro?dataContact.id_tiporubro:1,
         idusuario_vendedor: dataContact.id_tipousuario_vendedor ? dataContact.id_tipousuario_vendedor : null,
         idcomuna: dataContact.id_comuna ? dataContact.id_comuna : null,
         cargo: dataContact.cto_cargo ? dataContact.cto_cargo : '',
         email: dataContact.email ? dataContact.email : '',
         nombre: dataContact.cto_nombre ? dataContact.cto_nombre : '',
         telefono: dataContact.telefono ? dataContact.telefono : '',
         celular: dataContact.cto_celular ? dataContact.cto_celular : '',
         cobranza: dataContact.cto_cobranza ? dataContact.cto_cobranza : '',
         is_prospecto: dataContact.prospecto ? dataContact.prospecto : '0',
      });

      return resp;
   },
   deleteContact: async (id: number): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/contact/${id}`)
      return resp;
   },
   getDetailsContact: async (id: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)

      const { data, error, message } = resp;
      return {
         data,
         error,
         message
      }
   },
   getContactandUserByCampaing: async (id: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/detalle/id?campana=${id}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = resp;
      return {
         data,
         error,
         message
      }
   },

   getContactandUserAndCampaing: async (id: number, idusuario: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/detalle/clients?id=${id}&idusuario=${idusuario}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = resp;
      return {
         data,
         error,
         message
      }
   },


   postCompraDetalleByContact: async (id_cliente: any): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/contact/sincronizacion-detalleCompra/${id_cliente}`, {
      })

      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      }
   },

   postCompraDetalleByContactCampana: async (id_detalle_campana: any): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/contact/sincronizacion-detalleCompra/campana/${id_detalle_campana}`, {
      })

      return {
         status: resp.status,
         message: resp.message,
         data: resp.data
      }
   },

   getComprasbyClient: async (id_cliente: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/BuySync/${id_cliente}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = resp;
      return {
         data,
         error,
         message
      }
   },

   getComprasbyClientCampana: async (id_detalle_campana: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/BuySync/campana/${id_detalle_campana}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = resp;
      return {
         data,
         error,
         message
      }
   },

   getComprasbyClientDetalle: async (id_compra: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/BuySync-detalle/${id_compra}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      const { data, error, message } = resp;
      return {
         data,
         error,
         message
      }
   },

   getContatosbyCliente: async (idcliente): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/detContacto/${idcliente}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   createContactbyClient: async (dataContactbyClient): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/contact/detContacto`, {
         idcliente: dataContactbyClient.idcliente,
         cargo: dataContactbyClient.cargo,
         email: dataContactbyClient.email,
         nombre: dataContactbyClient.nombre,
         telefono: dataContactbyClient.telefono,
         celular: dataContactbyClient.celular,
         id_empresa: id_empresa,
         // cto_cobranza: dataContact.cto_cobranza,
         // idempresacliente: idempresacliente.user.idempresacliente
      })
      return {
         // error: resp.error,
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   updateContactbyClient: async (id: number, dataContactbyClient): Promise<any> => {
      const resp = await http.patch<any>(`${API_URL_BASE}/v1/contact/detContacto/${id}`, {
         idcliente: dataContactbyClient.idcliente,
         cargo: dataContactbyClient.cargo,
         email: dataContactbyClient.email,
         nombre: dataContactbyClient.nombre,
         telefono: dataContactbyClient.telefono,
         celular: dataContactbyClient.celular,
         // cto_cobranza: dataContact.cto_cobranza,
         // idempresacliente: idempresacliente.user.idempresacliente
      });

      return resp;
   },
   deleteContactbyClient: async (id: number): Promise<any> => {
      const resp = await http.delete<any>(`${API_URL_BASE}/v1/contact/detContacto/${id}`)
      return resp;
   },

   clienteRutSync: async (dataclienteRutSync): Promise<any> => {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/detailNegocioContacto/getClienteSync`,dataclienteRutSync)
      return {
         // error: resp.error,
         status: resp.status,
         message: resp.message,
         data: resp.data
      };
   },

   getCampaingbyCliente: async (id: number): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/getCampaign/${id}?rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   getFormAndIdbyClienteAndCampaing: async (idcliente = null, idcampana, form_cliente = null): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/leads/clienteOrForm?form_cliente=${form_cliente}&idcliente=${idcliente}&campana=${idcampana}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },

   createClientProspecto: async (dataClient): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.post<any>(`${API_URL_BASE}/v1/contact/createClientProspecto`,{
         dataClient: dataClient,
         id_empresa: id_empresa
      }
      )
      return resp
   },

   getClientesCampana: async (idcampana, usuarioVendedor = null): Promise<any> => {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const rut_empresa = dataUser?.user?.rut_empresa;
      const id_empresa = dataUser?.user?.idempresacliente;
      const id_usuario = dataUser?.user?.idusuario;
      const resp = await http.get<any>(`${API_URL_BASE}/v1/contact/listaClientCampana?campana=${idcampana}&usuarioVendedor=${usuarioVendedor}&rut_empresa=${rut_empresa}&id_empresa=${id_empresa}&id_usuario=${id_usuario}`)
      return resp
   },
}
