import { contactRepository } from "../repositories/Contact.repository";

export const contactService = {
   getContact,
   getContactAll,
   getContactByArriendo,
   getSearchContacts,
   getDataInitial,
   createContact,
   updateContact,
   deleteContact,
   getDetailsContact,
   verifyRut,
   getContactandUserByCampaing,
   getContactandUserAndCampaing,
   postCompraDetalleByContact,
   getComprasbyClientCampana,
   postCompraDetalleByContactCampana,
   getComprasbyClient,
   getComprasbyClientDetalle,
   getContatosbyCliente,
   createContactbyClient,
   clienteRutSync,
   getCampaingbyCliente,
   getFormAndIdbyClienteAndCampaing,
   updateContactbyClient,
   deleteContactbyClient,
   createClientProspecto,
   getClientesCampana
};

function getContact(perPage, page) {
   return contactRepository.getContact(perPage, page);
}
function getContactAll() {
   return contactRepository.getContactAll();
}
function getContactByArriendo() {
   return contactRepository.getContactByArriendo();
}
function getSearchContacts(search) {
   return contactRepository.getSearchContacts(search);
}
function createContact(contact) {
   return contactRepository.createContact(contact)
}
function updateContact(id, dataContact) {
   return contactRepository.updateContact(id, dataContact)
}
function deleteContact(id) {
   return contactRepository.deleteContact(id)
}
function getDetailsContact(id) {
   return contactRepository.getDetailsContact(id)
}
function getDataInitial() {
   return contactRepository.getDataInitial();
}
function verifyRut(rut_cliente) {
   return contactRepository.verifyRut(rut_cliente)
}
function getContactandUserByCampaing(id) {
   return contactRepository.getContactandUserByCampaing(id);
}
function getContactandUserAndCampaing(id,idusuario) {
   return contactRepository.getContactandUserAndCampaing(id,idusuario);
}

function postCompraDetalleByContact(comprasSync) {
   return contactRepository.postCompraDetalleByContact(comprasSync);
}
function postCompraDetalleByContactCampana(id_detalle_campana) {
   return contactRepository.postCompraDetalleByContactCampana(id_detalle_campana);
}
function getComprasbyClient(id_cliente){
   return contactRepository.getComprasbyClient(id_cliente);
}
function getComprasbyClientCampana(id_detalle_campana){
   return contactRepository.getComprasbyClientCampana(id_detalle_campana);
}
function getComprasbyClientDetalle(id_compra){
   return contactRepository.getComprasbyClientDetalle(id_compra);
}

function getContatosbyCliente(idcliente) {
   return contactRepository.getContatosbyCliente(idcliente)
}

function createContactbyClient(dataContactbyClient)
{
   return contactRepository.createContactbyClient(dataContactbyClient)
}

function clienteRutSync(dataContactbyClient)
{
   return contactRepository.clienteRutSync(dataContactbyClient)
}

function getCampaingbyCliente(idcliente)
{
   return contactRepository.getCampaingbyCliente(idcliente)
}

function getFormAndIdbyClienteAndCampaing(idcliente,idcampana,form_cliente) {
   return contactRepository.getFormAndIdbyClienteAndCampaing(idcliente,idcampana,form_cliente)
}

function updateContactbyClient(id, dataContact) {
   return contactRepository.updateContactbyClient(id, dataContact)
}
function deleteContactbyClient(id) {
   return contactRepository.deleteContactbyClient(id)
}

function createClientProspecto(dataClient) {
   return contactRepository.createClientProspecto(dataClient)
}

function getClientesCampana(idcampana,iduser) {
   return contactRepository.getClientesCampana(idcampana,iduser)
}
